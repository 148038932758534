.eth-container {
  width: 100%;
  margin: 0 auto;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
}

.eth-content {
  padding: 50px 0;
}

@media only screen and (min-width: 1200px) {
  .eth-container {
    width: 1124px;
  }
}

@media only screen and (max-width: 1199px) and (min-width: 990px) {
  .eth-container {
    width: 933px;
  }
}
