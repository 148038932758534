.Main > .eth-container {
  display: flex;
  flex-direction: column;
  height: calc(100% - 128px);
  margin: 128px auto 64px;
  justify-content: space-between;
  position: relative;
}

.Main > .eth-container > div {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.Main .LoginItemContainer {
  margin: 0 30px
}

@media screen and (min-height: 900px) {
  main.Main > .eth-container {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin: 0 auto;
  }
}