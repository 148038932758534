.Main .LoginItemNewContainer {
  margin: 0 30px 30px;
}

.LoginItemNewContainer {
  width: 272px;
  height: 374px;
  position: relative;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgba(22, 21, 24, 1);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 24px;
}

.LoginItemNewContainer.loading {
  cursor: default;
}

.LoginItemNewContainer .ui.loader.massive {
  box-sizing: border-box;
  border-radius: 100%;
  background-color: rgba(0,0,0, 0.2);
  /* box-shadow: 0 0 25px, 0 0 50px, 0 0 100px; */
}

.LoginItemNewContainer .loader-background {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 16px;
  background-color: rgba(67, 64, 74, .9);
  position: absolute;
  display: flex;
  flex-direction: column;
  color: white;
}

.LoginItemNewContainer .loader-background .ui.loader {
  position: relative;
  top: auto;
  left: auto;
  transform: unset;
}

.LoginItemNewContainer .loader-background .ui.button {
  color: var(--primary);
  font-size: 14px;
  line-height: 17px;
  border-radius: 12px;
  padding: 14px 46px;
  min-width: auto;
}

.LoginItemNewContainer .LoginItemNewContainer__BackLayer {
  width: 111%;
  height: 110%;
  border-radius: 26px;
  position: absolute;
  background: linear-gradient(116.34deg, #FFBC5B 0%, #FF2D55 50.52%, #C640CD 100%);
  padding: 4px;
  box-sizing: border-box;
  box-shadow: 0 5px 5px rgba(0,0,0,.4);
  transition: transform ease .3s, box-shadow ease .3s;
  transform: scale(.92, .94);
}

.LoginItemNewContainer.animate:hover .LoginItemNewContainer__BackLayer,
.LoginItemNewContainer.animate.active .LoginItemNewContainer__BackLayer {
  transform: scale(1, 1);
  box-shadow: 0 12px 20px rgba(0,0,0,.5);
}

.LoginItemNewContainer .LoginItemNewContainer__BackLayer::before {
  content: "";
  display: block;
  /* position: absolute; */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  border-radius: 24px;
  border: 4px solid white;
}
.LoginItemNewContainer .LoginItemNewContainer__Content {
  background-color: #ffffff;
  position: absolute;
  box-sizing: border-box;
  padding: 30px;
  overflow: hidden;
  width: 100%;
  height: 100%;
  border-radius: 16px;
}

.LoginItemNewContainer .LoginItemNewContainer__Content h2 {
  font-weight: 600;
  font-size: 16px;
  text-align: center;
  transform: translate(-3px, 32px);
  position: absolute;
  margin: 0 auto;
  top: 0;
  left: 0;
  width: 100%;
  transition: transform ease .3s;
}

.LoginItemNewContainer.animate.active .LoginItemNewContainer__Content h2,
.LoginItemNewContainer.animate:hover .LoginItemNewContainer__Content h2 {
  transform: translate(-3px, 22px);
}

.LoginItemNewContainer:not(.animate).active .LoginItemNewContainer__Content h2,
.LoginItemNewContainer:not(.animate):hover .LoginItemNewContainer__Content h2 {
  transform: translate(-3px, 32px);
}

.LoginItemNewContainer .LoginItemNewContainer__Content .ui.button {
  position: absolute !important;
  opacity: 1 !important;
  border-radius: 12px;
  bottom: 30px;
  transition: opacity ease .3s;
  width: calc(100% - 60px);
  box-sizing: border-box;
  font-weight: bold;
  font-size: 12.8px;
  line-height: 10px;
  text-transform: none;
  height: 36.8px;
}

.LoginItemNewContainer .LoginItemNewContainer__Content .ui.button.secondary {
  background-color: transparent;
  border: 1.5px solid #CFCDD4;
}

.LoginItemNewContainer.animate .LoginItemNewContainer__Content .ui.button {
  opacity: 0 !important;
}

.LoginItemNewContainer .LoginItemNewContainer__Content .ui.button:nth-of-type(2) {
  bottom: 76px !important;
}

.LoginItemNewContainer.animate.active .LoginItemNewContainer__Content .ui.button,
.LoginItemNewContainer.animate:hover .LoginItemNewContainer__Content .ui.button {
  opacity: 1 !important;
}

.LoginItemNewContainer.animate.active .LoginItemNewContainer__Content .ui.button:disabled,
.LoginItemNewContainer.animate:hover .LoginItemNewContainer__Content .ui.button:disabled {
  opacity: 0.5 !important;
}


.LoginWalletItem__SuccessLabelWrapper {
  position: absolute;
  left: 0;
  top: 0;
  transform: translate(30px, 94px);
  transition: transform ease .3s;
  width: calc(100% - 60px);
}

.LoginItemNewContainer.animate.active .LoginWalletItem__SuccessLabelWrapper,
.LoginItemNewContainer.animate:hover .LoginWalletItem__SuccessLabelWrapper {
  transform: translate(30px, 84px);
}

.LoginWalletItem__SuccessLabelWrapper .ui.label.LoginWalletItem__SuccessLabel {
  display: inline-block;
  font-size: 14.4px;
  line-height: 2.4;
  background-color: transparent;
  font-weight: 400;
  padding: 0;
  color: #716B7C;
}

.LoginWalletItem__SuccessLabelWrapper .ui.label.LoginWalletItem__SuccessLabel span:nth-child(1) {
  margin-left: 29px;
}

.LoginWalletItem__SuccessLabelWrapper .ui.label .LoginWalletItem__SuccessLabelIcon + span {
  color: #000000
}

.LoginWalletItem__SuccessLabelWrapper .ui.label .LoginWalletItem__SuccessLabelIcon {
  color: #36D41D;
  font-size: 14.4px;
}

@media only screen and (min-height: 900px) {
  .LoginItemNewContainer {
    width: 340px;
    height: 468px;
  }

  .LoginItemNewContainer .LoginItemNewContainer__Content {
    padding: 40px;
    border-radius: 20px;
  }

  .LoginItemNewContainer .LoginItemNewContainer__Content h2 {
    transform: translate(-3px, 50px);
    font-size: 20px;
  }

  .LoginItemNewContainer.animate.active .LoginItemNewContainer__Content h2,
  .LoginItemNewContainer.animate:hover .LoginItemNewContainer__Content h2 {
    transform: translate(-3px, 40px);
  }

  .LoginItemNewContainer:not(.animate).active .LoginItemNewContainer__Content h2,
  .LoginItemNewContainer:not(.animate):hover .LoginItemNewContainer__Content h2 {
    transform: translate(-3px, 50px);
  }

  .LoginItemNewContainer .LoginItemNewContainer__Content .ui.button {
    bottom: 30px;
    width: calc(100% - 80px);
    font-size: 16px;
    line-height: 19px;
    height: 46px;
  }

  .LoginItemNewContainer .LoginItemNewContainer__Content .ui.button {
    bottom: 40px;
    width: calc(100% - 80px);
    font-size: 16px;
    line-height: 19px;
    height: 46px;
  }

  .LoginItemNewContainer .LoginItemNewContainer__Content .ui.button:nth-of-type(2) {
    bottom: 100px !important;
  }

  .LoginWalletItem__SuccessLabelWrapper {
    transform: translate(40px, 118px);
    width: calc(100% - 80px);
  }

  .LoginItemNewContainer.animate.active .LoginWalletItem__SuccessLabelWrapper,
  .LoginItemNewContainer.animate:hover .LoginWalletItem__SuccessLabelWrapper {
    transform: translate(40px, 110px);
  }

  .LoginWalletItem__SuccessLabelWrapper .ui.label.LoginWalletItem__SuccessLabel {
    display: inline-block;
    font-size: 18px;
    line-height: 2.5;
    background-color: transparent;
    font-weight: 400;
    padding: 0;
  }

  .LoginWalletItem__SuccessLabelWrapper .ui.label .LoginWalletItem__SuccessLabelIcon {
    font-size: 18px;
  }

  .LoginItemNewContainer .loader-background {
    border-radius: 20px;
  }

}