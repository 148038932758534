.banner-container {
  background-color: #272727;
  color: white;
  position: relative;
}

.banner-container::after {
  content: "";
  display: block;
  position: absolute;
  background: linear-gradient(90.07deg, #FF2D55 1.61%, #FFBC5B 43.3%, #CA38CD 70.1%, #740CB4 96.9%);;
  width: 100%;
  height: 2px;
  bottom: 0;
}

.banner-container .banner-text {
  text-align: center;
  padding: 10px 37px;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
}

.banner-container .banner-text a {
  color: white;
  text-decoration: underline;
}

.banner-container .banner-close-button {
  position: absolute;
  width: 37px;
  height: 37px;
  border-radius: 100%;
  top: 0;
  right: 0;
  /* background-color: rgba(255, 255, 255, 0.05); */
  cursor: pointer;
  background: url("../../images/banner-close.svg") center center no-repeat;
}