@font-face {
  font-family: sfregular;
  src: url('../fonts/SF-UI-Text-Regular.otf');
}

@font-face {
  font-family: sfsemibold;
  src: url('../fonts/SF-UI-Text-Regular.otf');
}

#root {
  position: absolute;
  width: 100%;
  min-height: 100vh;
  min-width: 990px;
  top: 0;
  left: 0;
  overflow: auto;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
  display: block;
}

#root.full {
  width: 100vw;
  height: 100vh;
  position: fixed;
  min-height: auto;
  min-width: auto;
}

#root.hide {
  display: none;
}

#root::-webkit-scrollbar {
  display: none;
}

.dcl.navbar.fullscreen {
  position: absolute;
  top: 0;
  left: 0;
}

.WebsiteApp.withBanner .dcl.navbar.fullscreen {
  top: 54px
}

.dcl.navbar.fullscreen .ui.container {
  width: 100%!important;
  padding: 0 20px;
  box-sizing: border-box;
}

.dcl.navbar.fullscreen .ui.secondary.stackable.menu .item {
  color: white;
  opacity: .7;
  font-weight: 400;
}

.dcl.navbar.fullscreen .ui.secondary.stackable.menu .item:hover {
  opacity: 1;
}

.dcl.navbar.fullscreen .ui.secondary.stackable.menu .item.active {
  font-weight: bold;
  opacity: 1;
}

.ui.modal.dcl.login-modal {
  background-color: #E5E5E5;
  width: 630px;
  border-radius: 24px;
  border: 0;
}

.ui.modal.dcl.login-modal.login-new-modal {
  background-color: #FFFFFF;
  width: 600px;
}

.ui.modal.dcl.login-modal .dcl.modal-navigation-button.modal-navigation-close {
  width: 36px;
  height: 36px;
  background-color: rgba(22,21,24,0.05);
  border-radius: 36px;
}

.ui.modal.dcl.login-modal.login-new-modal .dcl.modal-navigation-button.modal-navigation-close {
  width: 40px;
  height: 40px;
  border-radius: 10px;
  right: -20px;
}

.ui.modal.dcl.login-modal .dcl.modal-navigation-button.modal-navigation-close::before,
.ui.modal.dcl.login-modal .dcl.modal-navigation-button.modal-navigation-close::after {
  content: "";
  display: block;
  width: 20px;
  height: 0px;
  border: 2px solid #161518;
  background-color: #161518;
  position: absolute;
  left: 8px;
  top: 16px;
  border-radius: 2px;
}

.ui.modal.dcl.login-modal.login-new-modal .dcl.modal-navigation-button.modal-navigation-close::before,
.ui.modal.dcl.login-modal.login-new-modal .dcl.modal-navigation-button.modal-navigation-close::after {
  border: 2px solid #716B7C;
  background-color: #716B7C;
  left: 10px;
  top: 18px;
}

.ui.modal.dcl.login-modal .dcl.modal-navigation-button.modal-navigation-close::before {
  transform: rotate(-45deg);
}

.ui.modal.dcl.login-modal .dcl.modal-navigation-button.modal-navigation-close::after {
  transform: rotate(45deg);
}

.ui.modal.dcl.login-modal .dcl.modal-navigation {
  padding-top: 70px;
  border-radius: 24px 24px 0 0;
}

.ui.modal.dcl.login-new-modal .dcl.modal-navigation {
  padding: 40px 0 0;
  width: calc(100% - 80px);
  margin: auto;
  align-items: flex-start;
}

.ui.modal.dcl.login-modal .dcl.modal-navigation-title {
  font-size: 26px;
  font-weight: 600;
  line-height: 31px;
}

.ui.modal.dcl.login-modal .dcl.modal-navigation-title {
  font-size: 24px;
}

.ui.modal.dcl.login-new-modal .dcl.modal-navigation-subtitle {
  text-align: left;
  font-size: 18px;
  word-spacing: 5px;
  color: #000000;
  margin-top: 20px;
}

.ui.modal.dcl.login-modal .content {
  background-color: #E5E5E5;
  width: 538px;
  margin: 52px auto 56px;
}

.ui.modal.dcl.login-modal.login-new-modal .content {
  background-color: transparent;
  width: calc(100% - 80px);
  margin: 30px auto 56px;
}

.ui.modal.dcl.login-modal .content small.message {
  font-size: 14px;
  line-height: 17px;
  font-weight: 400;
  color: #161518;
  margin-top: 43px;
}

.ui.modal.dcl.login-modal .content small.message a {
  text-decoration: underline;
}

.ui.modal.dcl.login-modal .content .dcl.option {
  width: 490px;
  box-shadow: 0 0 0;
  border-radius: 12px;
  background-color: rgba(255, 255, 255, 0.4);
  padding: 16px;
}

.ui.modal.dcl.login-modal.login-new-modal .content .dcl.option {
  width: 100%;
  box-shadow: 0 0 0;
  border: 1px solid #CFCDD4;
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 0.4);
  padding: 10px;
  height: 46px;
}

.ui.modal.dcl.login-modal .content .dcl.option:hover {
  box-shadow: 0 0 0;
  background-color: white;
}

.ui.modal.dcl.login-modal.login-new-modal .content .dcl.option .image {
  width: 38px;
  height: 38px;
  margin-left: -4px;
}

.ui.modal.dcl.login-modal.login-new-modal .content .dcl.option.incompatible .image {
  width: 28px;
  height: 28px;
  margin: 0;
}

.dcl.option.incompatible .image {
  background-image: url(../images/logo_incompatible.svg);
}

.ui.modal.dcl.login-modal .content .dcl.option .info .title {
  color: #161518;
  font-weight: 600;
  font-size: 23px;
  line-height: 28px;
  font-weight: 500;
}

.ui.modal.dcl.login-modal.login-new-modal .content .dcl.option .info .title {
  font-size: 16px;
  position: relative;
}

.ui.modal.dcl.login-modal.login-new-modal .content .dcl.option:nth-child(1) .info .title:after {
  content: 'popular';
  position: absolute;
  width: 71px;
  height: 21px;
  background-color: #FF2D55;
  right: 10px;
  padding: 0px 4px;
  color: #fff;
  border-radius: 20px;
  text-transform: uppercase;
  font-size: 11px;
  text-align: center;
  line-height: 2;
  top: 3.5px;
}

.ui.modal.dcl.login-modal .content .dcl.option .info .subtitle {
  color: #A09BA8;
  font-size: 19px;
  line-height: 23px;
  font-weight: 300;
  margin-top: 0;
  letter-spacing: 1px;
}

.ui.modal.dcl.login-modal.login-new-modal .content .dcl.option .info .subtitle {
  display: none;
}

.loader-background,
.ui.modal.dcl.login-modal .loader-background {
  background-color: rgba(0,0,0,.7);
  opacity: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.loader-background .EthConnectAdvice,
.ui.modal.dcl.login-modal .loader-background .EthConnectAdvice {
  max-width: 290px;
  text-align: center;
  color: white;
  font-weight: 500;
}

.ui.modal.dcl.login-modal .loader-background {
  border-radius: 24px;
  color: white;
}

.ui.modal.dcl.login-modal.login-new-modal .loader-background {
  border-radius: 20px;
}

.ui.modal.dcl.login-modal .loader-background .EthConnectAdvice {
  max-width: 380px;
}

.ui.modal.dcl.login-modal  .loader-background .ui.button {
  color: var(--primary);
  font-size: 14px;
  line-height: 17px;
  border-radius: 12px;
  padding: 14px 46px;
  min-width: auto;
}

.ui.modal.dcl.login-modal .loader {
  background-color: rgba(0,0,0,.2);
  border-radius: 100%;
}

.ui.modal.dcl.login-modal .ui.loader {
  position: relative;
  top: auto;
  left: auto;
  transform: unset;
}

.ui.modal.dcl.login-modal .ui.big.loader {
  width: 2.28571429rem;
  height: 2.28571429rem;
  font-size: 1em
}

.ui.modal.dcl.login-modal .ui.big.loader {
  width: 2.28571429rem;
  height: 2.28571429rem;
  font-size: 1em
}

.ui.modal.dcl.login-modal .ui.big.loader::after,
.ui.modal.dcl.login-modal .ui.big.loader::before {
  width: 2.28571429rem;
  height: 2.28571429rem;
  margin: 0 0 0 -1.14285714rem
}

.WebsiteApp {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.login-new-modal__incompatible-description {
  background-color: rgba(236, 235, 237, 0.4);
  width: 600px;
  margin-top: 40px;
  padding: 40px;
  margin-bottom: -56px;
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
}

.login-new-modal__incompatible-description h2 {
  font-size: 20px;
  margin-bottom: 0;
}

.login-new-modal__incompatible-description h3 {
  margin-top: 30px;
  margin-bottom: 10;
}

.login-new-modal__incompatible-description p {
  font-size: 18px;
  margin-bottom: 0;
}