.error-container {
  width: 100%;
  z-index: 20;
  max-width: 800px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin: 0 auto;
  padding: 0 1rem;
  color: white;
}

.error-container .error-background {
  display: block;
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: black;
  z-index: -1;
  opacity: 1;
}

.error-container .error-content {
  background-color: rgba(255, 255, 255, 0);
  width: 100%;
  max-width: 800px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  position: relative;
}

#error-no-mobile.error-container .error-content {
  justify-content: center;
  align-content: space-around;
}

#error-no-mobile.error-container .error-content > div {
  margin: 2rem auto;
  width: 100%;
  text-align: center;
}

.error-container .error-content .error-details,
.error-container .error-content .error-image {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
  position: relative;
}

.error-container .error-content .error-details {
  padding-top: 132px;
}

.error-container .error-content .error-image img {
  max-height: 330px;
  width: auto;
  height: auto;
  margin: 0 auto;
  display: block;
}


.error-container .error-content .error-no-mobile-image img {
  width: 100%;
  height: auto;
  max-width: 212px;
  margin: 0 auto;
  display: block;
}

.error-container .error-content .error-cta {
  text-align: right;
  display: flex;
  align-items: flex-end;
  flex-flow: column;
  margin-top: 20px;
}

.error-container .error-content .error-background-title {
  background-color: rgba(255, 255, 255, 0);
  display: block;
  max-width: 100vw;
  opacity: 0.1;
  font-family: 'SFUIText-Heavy', '-apple-system', Helvetica, Arial, serif;
  font-weight: 800;
  font-style: normal;
  font-size: 180px;
  color: rgba(255, 255, 255, 1);
  text-align: right;
  letter-spacing: 2px;
  line-height: 180px;
  pointer-events: none;
  position: absolute;
  top: 0;
  right: 0;
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.error-container .error-content .error-title,
.error-container .error-content .error-no-mobile-title {
  background-color: rgba(255, 255, 255, 0);
  font-family: 'SFUIText-Heavy', '-apple-system', Helvetica, Arial, serif;
  font-weight: 600;
  font-style: normal;
  font-size: 18px;
  color: rgba(255, 255, 255, 1);
  text-align: right;
  letter-spacing: 1.1px;
  line-height: 25px;
  margin-bottom: 25px;
}

.error-container .error-content .error-no-mobile-title {
  text-align: center;
}

.error-container .error-content .error-description {
  background-color: rgba(255, 255, 255, 0);
  font-family: 'SFUIText-Regular', Helvetica, Arial, serif;
  font-size: 18px;
  color: rgba(255, 255, 255, 1);
  text-align: right;
  font-weight: 300;
  line-height: 22px;
  overflow: auto;
  max-height: 40vh;
  max-width: 100%;
  word-break: break-word;
}

.error-container .error-content .error-description a {
  color: rgba(255, 255, 255, 1);
  text-decoration: none;
  font-weight: 800;
}

.error-container .error-content .error-description a:visited {
  color: rgba(255, 255, 255, 1);
}

.error-container .error-content div {
  display: inline-block;
}

.error-container .error-content {
  background-color: rgba(255, 255, 255, 0);
  width: 100%;
  max-width: 800px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}

.error-container .error-content .ui.button {
  margin-bottom: 1rem;
}

@media screen and (max-width: 800px) {
  .error-container {
    overflow: hidden;
  }

  .error-container .error-content .error-image {
    display: none;
  }
}
